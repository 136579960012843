import Link from "next/link";
import { useRouter } from "next/router";
import Image from "next/image";
import React, { useContext, useEffect, useState } from "react";
import { pathToRegexp } from "path-to-regexp";
import { useSession } from "next-auth/react";
import { UrlObject } from "url";

import { useAuth } from "hooks/use-auth";
import {
    ArrowSquareOut,
    Buildings,
    ChalkboardTeacher,
    Chats,
    Compass,
    CurrencyCircleDollar,
    EnvelopeSimple,
    Folder,
    Lifebuoy,
    Megaphone,
    RocketLaunch,
    Sparkle,
    UsersThree,
    X
} from "phosphor-react";

import {
    URI_CHATBOT,
    URI_CLIENTS,
    URI_COMMISSIONS,
    URI_COMMUNITY,
    URI_FORA_EMAIL,
    URI_HELP_PORTAL,
    URI_HOME,
    URI_LOGIN,
    URI_MARKETING,
    URI_ONBOARDING,
    URI_PARTNERS,
    URI_PARTNERS_SUPPLIERS,
    URI_RESOURCES,
    URI_SUPPLIERS,
    URI_SUPPLIERS_PERKS,
    URI_TRAINING_V2,
    URI_USER_PROFILE,
    URI_WHATS_NEW
} from "constants/urls";
import { NavTab } from "lib/types";
import { classNames } from "lib/utils";
import { StoreContext } from "store/index";
import { BookingPlatformViewModes } from "lib/types/supplier-database.types";

type AsideNavigation = {
    groupName: string;
    items: NavTab[];
};

// Opt out from static page optimizations
export async function getServerSideProps() {
    return { props: {} };
}

export default function Aside({
    layoutV2 = false
}: {
    layoutV2?: boolean; // Used to make Aside compatible with LayoutV2. Once we've fully deprecated Layout in favor of LayoutV2, we should apply this props changes permanently and remove this prop.
}) {
    const router = useRouter();
    const { user, userProfile, token, isFeatureEnabled } = useAuth();
    const [resizeHandler, setResizeHandler] = useState<any>(null);
    const { data: session } = useSession();
    const { bookingFiltersURI } = useContext(StoreContext);

    const navigation: AsideNavigation[] = [
        {
            groupName: "Travel",
            items: [
                {
                    name: "Bookings",
                    icon: <CurrencyCircleDollar />,
                    count: 1,
                    href: bookingFiltersURI || URI_COMMISSIONS
                },
                {
                    name: "Clients",
                    icon: <UsersThree />,
                    href: URI_CLIENTS
                },
                {
                    name: "Suppliers & Perks",
                    icon: <Buildings />,
                    href: URI_SUPPLIERS_PERKS,
                    comingSoon: false,
                    feature: true
                },
                {
                    name: "Partners",
                    icon: <Buildings />,
                    href: `${URI_PARTNERS}?view_mode=${
                        BookingPlatformViewModes.LIST
                    }&supplierType=hotels${
                        router.asPath?.includes(URI_PARTNERS)
                            ? "&clearAll=true"
                            : ""
                    }`,
                    comingSoon: false,
                    feature: false,
                    beta: !isFeatureEnabled("show_supplier_payment_speeds"),
                    featureIdentifier: "partners"
                }
            ]
        },
        {
            groupName: "Marketing",
            items: [
                {
                    name: "Marketing",
                    icon: <Megaphone />,
                    href: URI_MARKETING
                }
            ]
        },
        {
            groupName: "Support",
            items: [
                {
                    name: "Training",
                    icon: <ChalkboardTeacher />,
                    href: URI_TRAINING_V2
                },
                {
                    name: "Resources",
                    icon: <Folder />,
                    href: URI_RESOURCES
                }
            ]
        },
        {
            groupName: "EXTERNAL RESOURCES",
            items: [
                {
                    name: "Fora Email",
                    icon: <EnvelopeSimple />,
                    href: URI_FORA_EMAIL,
                    external: true
                },
                {
                    name: "Forum",
                    icon: <Chats />,
                    href: URI_COMMUNITY,
                    external: true
                }
            ]
        },
        {
            groupName: "HELP",
            items: [
                {
                    name: "Help",
                    icon: <Lifebuoy />,
                    href: URI_HELP_PORTAL
                },
                {
                    name: "Sidekick",
                    icon: <Compass />,
                    href: URI_CHATBOT,
                    feature: !isFeatureEnabled("enable_chatbot"),
                    beta: true
                },
                {
                    name: "What's New",
                    icon: <RocketLaunch />,
                    href: URI_WHATS_NEW,
                    feature: !isFeatureEnabled("whats_new")
                }
            ]
        },
        {
            groupName: "Experimental",
            items: []
        }
    ];

    useEffect(() => {
        const resizeHandler = throttle("resize", "optimizedResize");

        setResizeHandler(resizeHandler);

        document
            .getElementById("navbar-open")
            ?.addEventListener("click", showNavbar);
        document
            .getElementById("navbar-minimalize")
            ?.addEventListener("click", closeNavbar);

        return () => {
            window.removeEventListener("optimizedResize", resizeHandler);
            window.removeEventListener("click", showNavbar);
            window.removeEventListener("click", closeNavbar);
        };
    }, []);

    const throttle = (type: string, name: string, obj?: any) => {
        obj = obj || window;
        let running = false;
        let func = function () {
            if (running) {
                return;
            }
            running = true;
            requestAnimationFrame(function () {
                obj.dispatchEvent(new CustomEvent(name));
                running = false;
            });
        };
        obj.addEventListener(type, func);

        return func;
    };

    const closeNavbar = () => {
        document.getElementById("side-menu")?.classList.add("aside-mobile");
    };
    const showNavbar = () => {
        document.getElementById("side-menu")?.classList.remove("aside-mobile");
    };

    const impersonatedUserProfile = session?.impersonatedUserProfile;

    const handlePartnersUpdatingRoute =
        (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) =>
        async (item: NavTab) => {
            if (
                router.asPath.includes(URI_PARTNERS) &&
                (typeof item.href === "string"
                    ? item?.href === URI_PARTNERS
                    : item?.href?.pathname === URI_PARTNERS)
            ) {
                await router.push(URI_PARTNERS, undefined, {
                    unstable_skipClientCache: true
                });
                document.documentElement.scrollTop = 0;
            }
        };

    return router.pathname !== URI_LOGIN ? (
        <aside
            id="side-menu"
            className={classNames(
                impersonatedUserProfile && !layoutV2 && "lg:pt-[65px]",
                layoutV2 ? "top-0 left-0 lg:static lg:h-full" : "h-[100dvh]",
                "fixed top-0 transition-transform duration-300 lg:transform-none lg:opacity-100 z-[15] w-[340px] lg:w-64 aside-mobile"
            )}
        >
            <nav className="flex flex-col h-full pt-8 bg-mainHoliday">
                <div className="flex justify-between items-center px-6">
                    <div className="relative h-[21px] w-[70px] flex items-center flex-shrink-0">
                        <Image
                            src="/img/logo_fora_min.svg"
                            alt="Fora Travel"
                            fill
                            className="w-full relative object-contain inset-0 absolute" /*TODO check object-contain + inset-0 absolute*/
                            data-testid="logo-image-id"
                        />
                    </div>
                    <button
                        id="navbar-minimalize"
                        className="flex items-center justify-center h-8 w-8 rounded-full focus:outline-none z-100 focus:ring-2 focus:ring-inset focus:ring-gray-600 lg:hidden"
                    >
                        <span className="sr-only">Close sidebar</span>
                        <X className="h-8 w-8 text-main" />
                        {/*<!-- Heroicon name: outline/x -->*/}
                    </button>
                </div>

                <div className="mt-3 pt-3 pb-4 px-2 flex-1 overflow-hidden overflow-y-auto">
                    <nav className="group">
                        <Link
                            href={URI_HOME}
                            prefetch={false}
                            className={`flex items-center px-4 py-3 text-medium font-medium rounded-[8px]  ${
                                router.pathname === URI_HOME ||
                                router.pathname.includes(URI_ONBOARDING)
                                    ? "nav-active-holidays !font-bold"
                                    : ""
                            }`}
                            title={"Home"}
                        >
                            <Sparkle
                                className="flex items-center justify-center text-secondary group-hover:text-main mr-2 flex-shrink-0"
                                size={24}
                            />
                            Home
                        </Link>
                    </nav>
                    {navigation.map(group => {
                        const items = group.items
                            .map((item: NavTab) => {
                                // checks the href of the link against the current path, setting exact matching for the root path "/" but otherwise allow trailing wildcards
                                let url =
                                    typeof item.href === "string" &&
                                    item.href.indexOf("?") > 0
                                        ? item.href.substring(
                                              0,
                                              item.href.lastIndexOf("?")
                                          )
                                        : item.featureIdentifier === "bookings"
                                        ? URI_SUPPLIERS
                                        : item.featureIdentifier === "partners"
                                        ? URI_PARTNERS_SUPPLIERS
                                        : item.href;

                                let isActive =
                                    typeof url === "string" &&
                                    pathToRegexp(url, [], {
                                        sensitive: true,
                                        end: item.href === URI_HOME
                                    }).test(router.asPath);

                                const isEnabledFeature: any =
                                    typeof item.href === "string" &&
                                    isFeatureEnabled(item.href.slice(1));

                                return !!isEnabledFeature || !item.feature ? (
                                    <Link
                                        key={item.name}
                                        href={item.comingSoon ? "#" : item.href}
                                        as={
                                            item.comingSoon
                                                ? "#"
                                                : typeof item.href === "string"
                                                ? item.href
                                                : item.href.pathname || ""
                                        }
                                        onClick={e =>
                                            handlePartnersUpdatingRoute(e)(item)
                                        }
                                        prefetch={false}
                                        target={
                                            item.external ? "_target" : "_self"
                                        }
                                        className={classNames(
                                            isActive && !item.comingSoon
                                                ? "nav-active-holidays !font-bold"
                                                : "",
                                            "text-main group flex items-center px-4 py-3 text-sm font-medium rounded-md"
                                        )}
                                        title={item.name}
                                    >
                                        <div
                                            className="flex items-center justify-center text-secondary mr-2 flex-shrink-0 text-[24px] group-hover:text-main"
                                            aria-hidden="true"
                                        >
                                            {item.icon}
                                        </div>
                                        <span
                                            className="truncate group-hover:text-main text-smallLH18 lg:text-medium"
                                            data-testid={`feature-${item.name}`}
                                        >
                                            {item.name}
                                        </span>
                                        {item.comingSoon || item.beta ? (
                                            <span
                                                className={classNames(
                                                    isActive
                                                        ? "bg-[#656260]/25"
                                                        : "bg-navigationStrokeHoliday group-hover:bg-navigationStrokeHoliday/70",
                                                    "ml-auto text-white inline-block py-[5px] px-3 text-xs rounded-[60px]"
                                                )}
                                            >
                                                {item.beta ? "Beta" : "Soon"}
                                            </span>
                                        ) : item.external ? (
                                            <ArrowSquareOut
                                                className="ml-auto py-0.5"
                                                size={24}
                                            />
                                        ) : (
                                            ""
                                        )}
                                    </Link>
                                ) : null;
                            })
                            .filter(item => item != null);
                        return items.length > 0 ? (
                            <div className="" key={group.groupName}>
                                <h3
                                    className={classNames(
                                        group.groupName === "Travel"
                                            ? "border-none"
                                            : "",
                                        "my-1 border-b-[1px] border-navigationStrokeHoliday/50"
                                    )}
                                    id="projects-headline"
                                />
                                {items}
                            </div>
                        ) : null;
                    })}
                </div>

                {userProfile && (
                    <div className="px-2 w-full">
                        <Link
                            href={URI_USER_PROFILE}
                            prefetch={false}
                            className="flex items-center group px-4 py-4 pb-8 border-t-[1px] border-navigationStrokeHoliday/50"
                        >
                            <span className="mr-4 min-w-[36px] w-[36px] group-hover:text-primary flex items-center">
                                {userProfile.photo && (
                                    <img
                                        src={userProfile?.photo || ""}
                                        width={36}
                                        height={36}
                                        className="rounded-full"
                                        alt="Fora Travel"
                                    />
                                )}
                            </span>
                            <span
                                className={classNames(
                                    router.pathname === URI_USER_PROFILE
                                        ? "text-main !font-bold"
                                        : "text-main",
                                    "text-medium font-medium break-words group-hover:text-main"
                                )}
                            >
                                {userProfile.firstName} {userProfile.lastName}
                            </span>
                        </Link>
                    </div>
                )}
            </nav>
        </aside>
    ) : null;
}
